import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BbvPageProps, PrismicContactProps } from 'src/types'
import Layout from '../components/layout'
import * as styles from './contact.module.scss'

const ONCEHUB_JS_URL = 'https://cdn.oncehub.com/mergedjs/so.js'

export const query = graphql`
  query ContactQuery {
    prismicContact {
      data {
        title {
          raw
          text
        }
        description {
          raw
        }
        contact_charlie
      }
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const useScript = (src: string, id: string) => {
  useEffect(() => {
    if (typeof document === 'undefined') return

    const existing = document.getElementById(id)
    if (existing) existing.remove()

    const newScript = document.createElement('script')
    newScript.setAttribute('src', src)
    newScript.setAttribute('id', id)
    document.body.appendChild(newScript)
  }, [id, src])
}

const OncehubEmbed = ({ id }: { id: string }) => {
  useScript(ONCEHUB_JS_URL, 'oncehubScript')

  return (
    <div className={styles.onceHubEmbed}>
      <div
        id={`SOIDIV_${id}`}
        data-so-page={id}
        data-height="550"
        data-style="border: 1px solid #d8d8d8; height: 100%; min-width: 290px; max-width: 900px;"
        data-psz="00"
      ></div>
    </div>
  )
}

const Contact = (props: BbvPageProps<PrismicContactProps>) => {
  const { data } = props.data.prismicContact
  const settings = props.data?.prismicSettings?.data

  return (
    <Layout title={data.title.text} pageData={props.data}>
      <div className="page-container gray-container">
        <Container>
          <RichText render={data.title.raw} />
          <hr />

          <div className={styles.contactContainer}>
            <Row>
              <Col xs={12} md={settings?.oncehub_id ? 4 : 8}>
                <div className={styles.description}>
                  <RichText render={data.description.raw} />
                </div>
                {data.contact_charlie && (
                  <>
                    <div className={styles.generalInquiriesHeading}>Contact Charlie</div>
                    <a href={`mailto:${data.contact_charlie}`}>{data.contact_charlie}</a>
                  </>
                )}
              </Col>
              {!!settings?.oncehub_id && (
                <Col xs={12} md={8}>
                  <OncehubEmbed id={settings.oncehub_id} />
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(Contact)
